import { graphql, useStaticQuery } from 'gatsby';
import { useContext } from 'react';
import { AppContext } from '../context';

export const useResources = () => {
  const ctx = useContext(AppContext);
  const { allWpPost } = useStaticQuery(graphql`
    query AllResources {
      allWpPost(filter: { languageCode: { eq: "en" } }, sort: { fields: date, order: DESC }) {
        nodes {
          ...PostPreview
          languageCode
        }
      }
    }
  `);

  return allWpPost?.nodes.filter(post => post.languageCode === ctx?.locale);
};

export default useResources;
