import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import React from 'react';
import placeholderImage from '~/assets/temp-images/blogPlaceholder.jpg';
import placeholderImageLarge from '~/assets/temp-images/blogPlaceholderLarge.jpg';

import Image from '../elements/Image';
import { PreviewScale } from '../global/PostPreview';

const FeaturedResources = ({ featuredResources }) => {
  return (
    <div className="flex gap-10 flex-wrap md:flex-nowrap !max-w-[996px] w-full">
      <div className="w-full md:w-[60%]">
        <motion.div whileHover="hover">
          <Link to={featuredResources[0]?.uri}>
            <PreviewScale
              hoverScale={0.98}
              containerClass={'overflow-hidden rounded-[15px]'}
              imageClass={'relative aspect-w-588 aspect-h-360 image-absolute '}
            >
              <Image
                image={
                  featuredResources[0]?.featuredImage?.node
                    ? featuredResources[0]?.featuredImage?.node
                    : placeholderImageLarge
                }
                objectFit="cover"
                className={`rounded-[15px] overflow-hidden transform transform-cpu `}
              />
            </PreviewScale>
            <motion.h4
              className="mt-[29px] t-36 tracking-[-0.015em] post-preview-text-link"
              dangerouslySetInnerHTML={{ __html: featuredResources[0]?.title }}
            />
          </Link>
        </motion.div>
      </div>
      <div className="w-full md:w-[40%] flex flex-col gap-10 md:gap-[39px]">
        {featuredResources?.slice(1, 3)?.map((featPost, i) => (
          <motion.div key={`featPost${i}`} whileHover="hover">
            <Link to={featPost?.uri}>
              <PreviewScale
                hoverScale={0.975}
                containerClass={'overflow-hidden rounded-[15px]'}
                imageClass={'relative aspect-w-382 aspect-h-180 image-absolute overflow-hidden '}
              >
                <Image
                  objectFit="cover"
                  image={featPost?.featuredImage?.node ? featPost?.featuredImage?.node : placeholderImage}
                  className={`rounded-[15px] overflow-hidden max-h-[180px] transform transform-cpu`}
                />
              </PreviewScale>
              <h4
                className="mt-[25px] t-24 post-preview-text post-preview-text-linkSmall "
                dangerouslySetInnerHTML={{ __html: featPost?.title }}
              />
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedResources;
