import { graphql, Link } from 'gatsby';
import React, { useState, useContext } from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from './Layout';
import PostPreview, { PreviewScale } from '../components/global/PostPreview';
import SubscribeForm from '../components/flexible/SubscribeForm';
import FeaturedResources from '../components/flexible/FeaturedResources';
import Image from '../components/elements/Image';
import { motion } from 'framer-motion';
import { Button } from '../components/elements/Link';
import placeholderImage from '~/assets/temp-images/blogPlaceholder.jpg';
import translate from '~/utils/translate';
import { AppContext } from '../context';
import useResources from '~/hooks/useResources';


export default function categoryPage(props: any) {
  const {
    pageContext,
    data: { wp, wpCategory, allWpPost },
  } = props;

  let posts = wpCategory?.posts?.nodes;

  const [showMore, setShowMore] = useState(12);
  const ctx = useContext(AppContext);

  const allPosts = useResources();
  posts = allPosts.filter(post => post?.categories?.nodes?.some((cat) => cat?.slug === wpCategory?.slug))


  return (
    <Layout wp={wp}>
      <Seo post={wpCategory} />
      <section className="bg-lightNavy bannerTop max-w-[1354px] mx-auto px-[43px] text-white rounded-b-[40px]">
        <div className="max-w-[689px] mx-auto pt-[7rem] md:pt-[11rem] pb-[70px] md:pb-[104px] text-center">
          <h1 className="t-62 !text-white">{wpCategory?.name}</h1>
        </div>
      </section>

      <div className={`my-12 md:mt-[116px]  md:mb-[118px]  container mx-auto flex justify-center`}>
        {wpCategory?.name === 'Guides' ? (
          <div className="max-w-[995px] mx-auto w-full">
            <ResourceGrid posts={posts?.slice(0, 12)} />
          </div>
        ) : wpCategory?.name === 'Ebooks' ? (
          <EbookGrid posts={posts} />
        ) : wpCategory?.name === 'Webinars' ? (
          <WebinarGrid posts={posts} />
        ) : (
          <InsightsGrid posts={posts} />
        )}
      </div>

      <SubscribeForm />

      <div className=" my-12 md:mt-[116px] md:mb-[118px]  !max-w-[995px] container mx-auto">
        <ResourceGrid posts={posts?.slice(0, showMore)} />
        <div className="flex justify-center mt-12 text-white ">
          {posts?.length > showMore && (
            <div onClick={() => setShowMore(showMore + 6)} className="group">
              <Button link={{ title: translate('Load more') }} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export const ResourceGrid = ({ posts }) => {
  return (
    <div className="flex flex-wrap ml-[-27px] gap-y-8 md:gap-y-[60px] ">
      {posts?.map((post, i) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: i / 12 }}
          key={post?.id}
          className="w-full md:w-1/3 pl-[27px]"
        >
          <PostPreview {...post} />
        </motion.div>
      ))}
    </div>
  );
};

export const InsightsGrid = ({ posts }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <FeaturedResources featuredResources={posts} />
      <div className="bg-black bg-opacity-[0.24] h-px my-[90px] w-full" />
      <div className="max-w-[995px] mx-auto">
        <ResourceGrid posts={posts?.slice(1, 7)} />
      </div>
    </div>
  );
};
export const EbookGrid = ({ posts }) => {
  return (
    <div className="max-w-[1041px] mx-auto w-full">
      <div className="flex ml-[-29px] flex-wrap gap-y-10 md:gap-y-[75px] ">
        {posts?.map((post, i) => (
          <div key={`ebook${i}`} className="pl-[29px] w-full md:w-1/2">
            <EbookPreview {...post} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const EbookPreview = ({ featuredImage, blogPost, title, uri }) => {
  const image = featuredImage?.node ? featuredImage?.node : placeholderImage;
  return (
    <motion.div whileHover="hover">
      <Link to={uri} className="md:max-w-[506px]">
        <motion.div variants={{ hover: { scale: 0.975 } }} className="relative  md:max-w-[506px] ">
          <div
            style={{ border: '1px solid #ccc' }}
            className="relative  aspect-w-312 aspect-h-147 bg-grey w-full image-absolute  rounded-[15px] overflow-hidden  flex items-center justify-center"
          >
            <Image objectFit="cover" image={image} className={`w-full h-full`} />
          </div>
        </motion.div>
        <div className="flex md:max-w-[457px]">
          <h4
            className="mt-[34px] t-36 !tracking[-0.015em] "
            dangerouslySetInnerHTML={{ __html: `${blogPost?.previewTeaser ? blogPost?.previewTeaser : title}` }}
          />
        </div>
      </Link>
    </motion.div>
  );
};

export const WebinarGrid = ({ posts }) => {
  const featuredImage = posts[0]?.featuredImage?.node;
  const image = featuredImage?.node ? featuredImage?.node : placeholderImage;
  return (
    <div className="w-full">
      <motion.div whileHover="hover">
        <Link
          to={posts[0]?.uri}
          className="flex items-center justify-between max-w-[1030px] flex-wrap md:flex-nowrap mx-auto gap-x-6"
        >
          <PreviewScale
            hoverScale={0.975}
            containerClass={'overflow-hidden rounded-[15px] w-full max-w-[504px]'}
            imageClass={'relative aspect-w-504 aspect-h-309 overflow-hidden  image-absolute bg-grey rounded-[15px]'}
          >
            <Image objectFit="cover" image={image} className={`w-full rounded-[15px] overflow-hidden   `} />
          </PreviewScale>

          <div className="max-w-[451px]">
            <h4
              className="mt-[34px] t-36 post-preview-text post-preview-text-link "
              dangerouslySetInnerHTML={{ __html: posts[0]?.blogPost?.previewTeaser }}
            />
          </div>
        </Link>
      </motion.div>
      <div className="bg-black bg-opacity-[0.24] h-px my-12 md:my-[90px] w-full max-w-[993px] mx-auto" />
      <div className="flex justify-center max-w-[995px] mx-auto">
        <ResourceGrid posts={posts?.slice(1, 7)} />
      </div>
    </div>
  );
};

function Pagination({ prev, next }) {
  return (
    <>
      {(prev || next) && (
        <div className="flex items-center justify-center mx-auto mt-12 space-x-3 text-14px">
          {prev && (
            <div>
              <Link to={prev}>{translate('Previous')}</Link>
            </div>
          )}
          {next && (
            <div>
              <Link to={next}>{translate('Next')}</Link>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export const pageQuery = graphql`
  query Category($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }

    wp {
      ...GeneratedWp
    }

    wpCategory(id: { eq: $id }) {
      ...GeneratedWpCategory
      ...CategorySEO
    }

    # allWpPost(
    #   filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    # ) {
    #   nodes {
    #     ...PostPreview
    #   }
    # }
  }
`;
