import React from 'react';
import translate from '~/utils/translate';

const SubscribeForm = () => {
  return (
    <section className="container my-12 md:my-28">
      <div className="bg-orange text-white rounded-[25px] px-6 pt-12 pb-12 md:pb-0 md:px-20 md:pt-20">
        <div className="max-w-[636px]">
          <h6 className="t-subheading !text-white">{translate('Subscribe')}</h6>
          <h2 className="mt-4 md:mt-[26px] t-36 !tracking-[-0.015em]">
            {translate('Subscribe to our newsletter and be the first to hear about latest news, content, and product updates.')}
          </h2>

          {/* <div className="mt-5 md:mt-[44px] w-full">
            <form className="flex bg-white rounded-[20px] pr-[12px] items-center " action="">
              <input
                className="bg-white t-18 placeholder-darkestNavy placeholder-opacity-40  subscribe-form  text-black rounded-[20px] flex-auto border-none focus:outline-none w-full pl-[15px] md:pl-[35px] py-[25px] "
                placeholder="Enter your email"
                type="text"
              />
              <button className="bg-darkestNavy transition-colors duration-200 hover:bg-opacity-90 pt-[12px] pb-[15px] px-[13px] md:px-[34px] max-h-[50px] t-18 rounded-[15px]">
                Subscribe
              </button>
            </form>
          </div> */}

          <div className="mt-6 ">
            <iframe
              src="https://email.opengamma.com/l/567582/2020-11-26/pjltzd"
              width="100%"
              height="150"
              type="text/html"
              frameborder="0"
              allowtransparency="true"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscribeForm;
